<template>
  <section id="section1" class="u-min-h-[610px] sm:u-min-h-[720px] u-relative">
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] u-py-20 sm:u-py-0 xl:u-px-0 xl:u-flex xl:u-items-center">
      <div
        class="u-mx-auto sm:u-mt-0 u-w-[clamp(317px,50%,744px)] sm:u-absolute sm:u-right-[clamp(0rem,-9.25rem+23.125vw,9.25rem)] sm:u-bottom-[clamp(2.5rem,-4.25rem+16.875vw,9.25rem)] u-text-center">
        <picture
          class="u-inline-flex u-aspect-[744/410] u-w-full h-auto float-ease-in-out">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-section1-hola-sm@1x.webp 1x,
              /img/home-section1-hola-sm@2x.webp 2x,
              /img/home-section1-hola-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-section1-hola-xs@1x.webp 1x,
              /img/home-section1-hola-xs@2x.webp 2x,
              /img/home-section1-hola-xs@3x.webp 3x
            " />
          <img
            src="/img/home-section1-hola-sm@1x.webp"
            alt="home-hola"
            class="u-object-cover u-w-full u-h-full" />
        </picture>
      </div>

      <div class="u-relative u-z-10 u-pt-6 sm:u-pt-[100px] xl:u-pt-[180px]">
        <h2
          class="u-mt-[16px] u-mb-[34px] u-whitespace-pre-line u-text-center u-font-bold u-text-[26px] u-leading-[34px] u-text-[#ffffff] sm:u-whitespace-pre-line sm:u-text-left sm:u-text-[55px] sm:u-leading-[80px]">
          <span>{{ $t('home.section1.title.0') }}</span>
          <br />
          <span>{{ $t('home.section1.title.1') }}</span>
        </h2>
        <div class="u-flex u-justify-center sm:u-justify-start">
          <div
            class="u-inline-grid u-grid-rows-2 u-grid-flow-col u-gap-x-2 sm:u-gap-x-[50px]">
            <span
              class="u-text-[#ffffff] u-font-bold u-text-[14px] u-leading-[22px] sm:u-text-[28px] sm:u-leading-[42px]"
              >{{ $t('home.section1.feat1.title') }}</span
            >
            <span
              class="u-text-[#ffffff] u-text-[14px] u-leading-[22px] sm:u-text-[15px] sm:u-leading-[22px]"
              >{{ $t('home.section1.feat1.desc') }}</span
            >
            <span
              class="u-text-[#ffffff] u-font-bold u-text-[14px] u-leading-[22px] sm:u-text-[28px] sm:u-leading-[42px]"
              >{{ $t('home.section1.feat2.title') }}</span
            >
            <span
              class="u-text-[#ffffff] u-text-[14px] u-leading-[22px] sm:u-text-[15px] sm:u-leading-[22px]"
              >{{ $t('home.section1.feat2.desc') }}</span
            >
            <span
              class="u-text-[#ffffff] u-font-bold u-text-[14px] u-leading-[22px] sm:u-text-[28px] sm:u-leading-[42px]"
              >{{ $t('home.section1.feat3.title') }}</span
            >
            <span
              class="u-text-[#ffffff] u-text-[14px] u-leading-[22px] sm:u-text-[15px] sm:u-leading-[22px]"
              >{{ $t('home.section1.feat3.desc') }}</span
            >
            <span
              class="u-text-[#ffffff] u-font-bold u-text-[14px] u-leading-[22px] sm:u-text-[28px] sm:u-leading-[42px]"
              >{{ $t('home.section1.feat4.title') }}</span
            >
            <span
              class="u-text-[#ffffff] u-text-[14px] u-leading-[22px] sm:u-text-[15px] sm:u-leading-[22px]"
              >{{ $t('home.section1.feat4.desc') }}</span
            >
          </div>
        </div>
        <div
          class="u-mt-[34px] sm:u-mt-4 u-mb-3 u-min-h-10 u-min-w-full sm:u-min-w-[464px] sm:u-min-h-[58px] u-inline-grid u-grid-rows-2 u-grid-cols-1 sm:u-grid-rows-1 sm:u-grid-cols-2 u-items-stretch u-gap-3 sm:u-gap-5">
          <q-btn
            :label="$t('home.section1.exploreBtn')"
            :to="'/pricing?lang=' + userStore.lang"
            rounded
            flat
            class="u-px-4 u-min-h-11 u-flex-1 u-bg-[#FCB400] u-font-bold u-text-[16px] u-leading-[24px] sm:u-px-6 sm:u-text-[20px]" />
          <q-btn
            :label="$t('home.section1.downloadBtn')"
            :to="'/download?lang=' + userStore.lang"
            outline
            rounded
            class="u-px-4 u-min-h-11 u-flex-1 u-text-[#FCB400] u-font-bold u-text-[16px] u-leading-[24px] sm:u-px-6 sm:u-text-[20px]" />
        </div>
      </div>
    </div>
  </section>
  <section
    id="section2"
    class="u-max-w-[1149px] u-m-auto u-px-[29px] u-py-20 sm:u-pt-[140px] sm:u-pb-[150px] xl:u-px-0">
    <h2
      class="u-mt-0 u-mb-5 sm:u-mb-[140px] u-text-center u-text-[#1C1C1C] u-font-bold u-text-[26px] u-leading-[34px] sm:u-text-[36px] sm:u-leading-[50px]">
      <span>{{ $t('home.section2.title') }}</span>
    </h2>
    <div
      class="u-grid u-grid-flow-col u-grid-rows-[repeat(12,auto)] sm:u-grid-cols-3 sm:u-grid-rows-[repeat(4,auto)] u-gap-y-[20px] sm:u-gap-x-[30px] lg:u-gap-x-[100px]">
      <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
        <source
          media="(min-width: 640px)"
          srcset="
            /img/home-section2-feat1-sm@1x.webp 1x,
            /img/home-section2-feat1-sm@2x.webp 2x,
            /img/home-section2-feat1-sm@3x.webp 3x
          " />
        <source
          media="(min-width: 0px)"
          srcset="
            /img/home-section2-feat1-xs@1x.webp 1x,
            /img/home-section2-feat1-xs@2x.webp 2x,
            /img/home-section2-feat1-xs@3x.webp 3x
          " />
        <img
          src="/img/home-section2-feat1-sm@1x.webp"
          alt="home-hola"
          class="u-object-contain u-w-full u-h-full" />
      </picture>
      <h3
        class="u-mt-5 u-mb-0 u-text-center sm:u-text-left u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
        {{ $t('home.section2.feat1.title') }}
      </h3>
      <p
        class="u-mb-0 u-text-center sm:u-text-left u-text-[#6A6A6A] u-text-[14px] u-leading-[22px] sm:u-text-base">
        {{ $t('home.section2.feat1.desc') }}
      </p>
      <div class="u-text-center sm:u-text-left">
        <router-link
          :to="'/download?lang=' + userStore.lang"
          class="u-px-5 u-min-h-[35px] u-inline-flex u-justify-center u-items-center u-border u-border-solid u-border-[#FCB400] u-text-[#FCB400] u-rounded-full u-no-underline"
          >{{ $t('home.section2.feat1.actionBtn') }}</router-link
        >
      </div>
      <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
        <source
          media="(min-width: 640px)"
          srcset="
            /img/home-section2-feat2-sm@1x.webp 1x,
            /img/home-section2-feat2-sm@2x.webp 2x,
            /img/home-section2-feat2-sm@3x.webp 3x
          " />
        <source
          media="(min-width: 0px)"
          srcset="
            /img/home-section2-feat2-xs@1x.webp 1x,
            /img/home-section2-feat2-xs@2x.webp 2x,
            /img/home-section2-feat2-xs@3x.webp 3x
          " />
        <img
          src="/img/home-section2-feat2-sm@1x.webp"
          alt="home-hola"
          class="u-object-contain u-w-full u-h-full" />
      </picture>
      <h3
        class="u-mt-5 u-mb-0 u-text-center sm:u-text-left u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
        {{ $t('home.section2.feat2.title') }}
      </h3>
      <p
        class="u-mb-0 u-text-center sm:u-text-left u-text-[#6A6A6A] u-text-[14px] u-leading-[22px] sm:u-text-base">
        {{ $t('home.section2.feat2.desc') }}
      </p>
      <div class="u-text-center sm:u-text-left">
        <router-link
          :to="'/download?lang=' + userStore.lang"
          class="u-px-5 u-min-h-[35px] u-inline-flex u-justify-center u-items-center u-border u-border-solid u-border-[#FCB400] u-text-[#FCB400] u-rounded-full u-no-underline">
          {{ $t('home.section2.feat2.actionBtn') }}</router-link
        >
      </div>
      <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
        <source
          media="(min-width: 640px)"
          srcset="
            /img/home-section2-feat3-sm@1x.webp 1x,
            /img/home-section2-feat3-sm@2x.webp 2x,
            /img/home-section2-feat3-sm@3x.webp 3x
          " />
        <source
          media="(min-width: 0px)"
          srcset="
            /img/home-section2-feat3-xs@1x.webp 1x,
            /img/home-section2-feat3-xs@2x.webp 2x,
            /img/home-section2-feat3-xs@3x.webp 3x
          " />
        <img
          src="/img/home-section2-feat3-sm@1x.webp"
          alt="home-hola"
          class="u-object-contain u-w-full u-h-full" />
      </picture>
      <h3
        class="u-mt-5 u-mb-0 u-text-center sm:u-text-left u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
        {{ $t('home.section2.feat3.title') }}
      </h3>
      <p
        class="u-mb-0 u-text-center sm:u-text-left u-text-[#6A6A6A] u-text-[14px] u-leading-[22px] sm:u-text-base">
        {{ $t('home.section2.feat3.desc') }}
      </p>
      <div class="u-text-center sm:u-text-left">
        <router-link
          :to="'/download?lang=' + userStore.lang"
          class="u-px-5 u-min-h-[35px] u-inline-flex u-justify-center u-items-center u-border u-border-solid u-border-[#FCB400] u-text-[#FCB400] u-rounded-full u-no-underline">
          {{ $t('home.section2.feat3.actionBtn') }}</router-link
        >
      </div>
    </div>
  </section>
  <section id="section3" class="u-bg-[#F7F7F7]">
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] u-pt-20 sm:u-pt-[158px] u-pb-20 sm:u-pb-[83px] xl:u-px-0">
      <h2
        class="u-mt-0 u-mb-15 sm:u-mb-[30px] u-whitespace-pre-line u-text-center u-font-bold u-text-[26px] u-leading-[34px] u-text-[#1c1c1c] sm:u-text-[36px] sm:u-leading-[52px]">
        {{ $t('home.section3.title') }}
      </h2>
      <p
        class="u-mb-15 u-mx-auto sm:u-mb-[30px] u-max-w-[832px] u-text-center u-text-[#6A6A6A] u-text-[14px] u-leading-[22px] sm:u-text-[16px] sm:u-leading-[25px]">
        <span>{{ $t('home.section3.desc') }}</span>
      </p>
      <div class="u-flex u-justify-center">
        <div
          class="u-inline-grid u-grid-rows-2 u-grid-cols-2 u-gap-10 sm:u-grid-rows-1 sm:u-grid-cols-4">
          <div
            v-for="(item, index) in ['iOS', 'Android', 'Windows', 'macOS']"
            :key="index"
            class="u-h-[30px] u-border u-flex u-items-center u-gap-x-3 sm:u-gap-x-4">
            <img
              loading="lazy"
              class="u-w-8 u-h-8 sm:u-w-10 sm:u-h-10"
              :alt="`${item} icon`"
              :src="`/icons/goduck-${item.toLowerCase()}-icon.svg`" />
            <span
              class="u-text-[#666666] u-text-[14px] u-leading-[16px] sm:u-text-[16px] sm:u-leading-[20px]">
              {{ item }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-text-center">
      <picture
        class="u-inline-flex u-aspect-[1638/595] u-w-[clamp(19.8125rem,-0.2269rem+85.5016vw,102.375rem)]">
        <source
          media="(min-width: 640px)"
          srcset="
            /img/home-section3-banner-sm@1x.webp 1x,
            /img/home-section3-banner-sm@2x.webp 2x,
            /img/home-section3-banner-sm@3x.webp 3x
          " />
        <source
          media="(min-width: 0px)"
          srcset="
            /img/home-section3-banner-xs@1x.webp 1x,
            /img/home-section3-banner-xs@2x.webp 2x,
            /img/home-section3-banner-xs@3x.webp 3x
          " />
        <img
          src="/img/home-section3-banner-sm@1x.webp"
          alt="home-hola"
          class="u-object-cover u-w-full u-h-full" />
      </picture>
    </div>
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] u-pt-[40px] sm:u-pt-[134px] u-pb-20 sm:u-pb-[153px] xl:u-px-0">
      <div
        class="u-grid u-grid-flow-col u-grid-rows-[repeat(9,auto)] sm:u-grid-cols-3 sm:u-grid-rows-[repeat(3,auto)] u-gap-y-[20px] sm:u-gap-x-[30px] lg:u-gap-x-[100px]">
        <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-section3-feat1-sm@1x.webp 1x,
              /img/home-section3-feat1-sm@2x.webp 2x,
              /img/home-section3-feat1-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-section3-feat1-xs@1x.webp 1x,
              /img/home-section3-feat1-xs@2x.webp 2x,
              /img/home-section3-feat1-xs@3x.webp 3x
            " />
          <img
            src="/img/home-section3-feat1-sm@1x.webp"
            alt="home-hola"
            class="u-object-contain u-w-full u-h-full" />
        </picture>
        <h3
          class="u-mt-5 u-mb-0 u-text-center u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
          {{ $t('home.section3.feat1.title') }}
        </h3>
        <p
          class="u-mb-0 u-text-center u-text-[#6A6A6A] u-text-sm sm:u-text-base">
          {{ $t('home.section3.feat1.desc') }}
        </p>
        <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-section3-feat2-sm@1x.webp 1x,
              /img/home-section3-feat2-sm@2x.webp 2x,
              /img/home-section3-feat2-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-section3-feat2-xs@1x.webp 1x,
              /img/home-section3-feat2-xs@2x.webp 2x,
              /img/home-section3-feat2-xs@3x.webp 3x
            " />
          <img
            src="/img/home-section3-feat2-sm@1x.webp"
            alt="home-hola"
            class="u-object-contain u-w-full u-h-full" />
        </picture>
        <h3
          class="u-mt-5 u-mb-0 u-text-center u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
          {{ $t('home.section3.feat2.title') }}
        </h3>
        <p
          class="u-mb-0 u-text-center u-text-[#6A6A6A] u-text-sm sm:u-text-base">
          {{ $t('home.section3.feat2.desc') }}
        </p>
        <picture class="u-mt-15 u-w-full u-h-[160px] sm:u-mt-0 sm:u-h-[190px]">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-section3-feat3-sm@1x.webp 1x,
              /img/home-section3-feat3-sm@2x.webp 2x,
              /img/home-section3-feat3-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-section3-feat3-xs@1x.webp 1x,
              /img/home-section3-feat3-xs@2x.webp 2x,
              /img/home-section3-feat3-xs@3x.webp 3x
            " />
          <img
            src="/img/home-section3-feat3-sm@1x.webp"
            alt="home-hola"
            class="u-object-contain u-w-full u-h-full" />
        </picture>
        <h3
          class="u-mt-5 u-mb-0 u-text-center u-text-[18px] u-leading-[25px] u-font-bold u-text-[#1c1c1c] u-text-lg sm:u-text-[20px] sm:u-leading-[28px]">
          {{ $t('home.section3.feat3.title') }}
        </h3>
        <p
          class="u-mb-0 u-text-center u-text-[#6A6A6A] u-text-sm sm:u-text-base">
          {{ $t('home.section3.feat3.desc') }}
        </p>
      </div>
    </div>
  </section>
  <section id="section4">
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] u-py-20 sm:u-pt-[135px] sm:u-pb-[140px] xl:u-px-0">
      <h2
        class="u-mt-0 u-mb-5 sm:u-mb-0 u-whitespace-pre-line u-text-center u-font-bold u-text-[26px] u-leading-[34px] u-text-[#1c1c1c] sm:u-text-[36px] sm:u-leading-[52px]">
        {{ $t('home.section4.title') }}
      </h2>
      <AppReviews :source="reviews.list" />
    </div>
  </section>
  <section id="section5" class="sm:u-min-h-[834px] sm:-u-mb-[130px]">
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] xl:u-px-0 u-py-20 sm:u-py-0 u-flex u-flex-col-reverse sm:u-flex-row sm:u-gap-x-15">
      <div class="u-pt-10 sm:u-pt-[119px] u-text-center">
        <picture
          class="u-inline-flex u-aspect-[498/462] u-w-[clamp(19.8125rem,14.1563rem+14.1406vw,31.125rem)]">
          <source
            media="(min-width: 640px)"
            srcset="
              /img/home-section5-hola-sm@1x.webp 1x,
              /img/home-section5-hola-sm@2x.webp 2x,
              /img/home-section5-hola-sm@3x.webp 3x
            " />
          <source
            media="(min-width: 0px)"
            srcset="
              /img/home-section5-hola-xs@1x.webp 1x,
              /img/home-section5-hola-xs@2x.webp 2x,
              /img/home-section5-hola-xs@3x.webp 3x
            " />
          <img
            src="/img/home-section5-hola-sm@1x.webp"
            alt="home-hola"
            class="u-object-cover u-w-full u-h-full" />
        </picture>
      </div>
      <div class="sm:u-flex-1 sm:u-pt-[142px]">
        <h2
          class="u-mt-0 u-font-bold u-text-[26px] u-leading-[34px] u-text-[#ffffff] sm:u-whitespace-pre-line sm:u-text-[46px] sm:u-leading-[52px]">
          {{ $t('home.section5.title') }}
        </h2>
        <p
          class="u-text-[#ffffff] u-text-[14px] u-leading-[22px] sm:u-text-[16px] sm:u-leading-[28px]">
          {{ $t('home.section5.desc') }}
        </p>
        <p
          class="u-text-[#B5B5B5] u-text-[14px] u-leading-[22px] sm:u-text-[16px] sm:u-leading-[28px]">
          {{ $t('home.section5.hint') }}
        </p>
        <div class="u-mt-5">
          <router-link
            :to="'/download?lang=' + userStore.lang"
            class="u-px-5 u-min-h-[35px] u-inline-flex u-justify-center u-items-center u-border u-border-solid u-border-[#FCB400] u-text-[#FCB400] u-rounded-full u-no-underline">
            {{ $t('home.section5.freeTrialBtn') }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <AppFooter />
</template>

<script setup>
import { useMeta } from 'quasar'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import AppFooter from 'src/components/Footer.vue'
import { usePostStore } from 'src/stores/post'
import { metaLinkLocale, metaTitleTemplate } from 'src/utils/util'
import { useUserStore } from 'src/stores/user'
import AppReviews from './app-reviews.vue'

const route = useRoute()
const { t, availableLocales } = useI18n()
const userStore = useUserStore()

const postStore = usePostStore()
const { reviews } = storeToRefs(postStore)

defineOptions({
  preFetch: ({ store }) =>
    usePostStore(store)
      .resumePageHome()
      .catch((err) => console.error(err))
})

useMeta({
  title: t('home.title'),
  titleTemplate: metaTitleTemplate,
  meta: {
    description: {
      name: 'description',
      content: t('home.description')
    }
  },
  link: metaLinkLocale(route.fullPath, availableLocales)
})

// TODO img rwd
</script>

<style lang="scss" scoped>
#section1 {
  background-color: #edeff5;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('/img/home-section1-bg-xs@1x.webp');
  background-image: image-set(
    url('/img/home-section1-bg-xs@1x.webp') 1x,
    url('/img/home-section1-bg-xs@2x.webp') 2x,
    url('/img/home-section1-bg-xs@3x.webp') 3x
  );
}
@media screen and (min-width: 640px) {
  #section1 {
    background-position: center center;
    background-image: url('/img/home-section1-bg-sm@1x.webp');
    background-image: image-set(
      url('/img/home-section1-bg-sm@1x.webp') 1x,
      url('/img/home-section1-bg-sm@2x.webp') 2x,
      url('/img/home-section1-bg-sm@3x.webp') 3x
    );
  }
}

#section5 {
  background-color: #edeff5;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('/img/home-section5-bg-xs@1x.webp');
  background-image: image-set(
    url('/img/home-section5-bg-xs@1x.webp') 1x,
    url('/img/home-section5-bg-xs@2x.webp') 2x,
    url('/img/home-section5-bg-xs@3x.webp') 3x
  );
}
@media screen and (min-width: 640px) {
  #section5 {
    background-position: center center;
    background-image: url('/img/home-section5-bg-sm@1x.webp');
    background-image: image-set(
      url('/img/home-section5-bg-sm@1x.webp') 1x,
      url('/img/home-section5-bg-sm@2x.webp') 2x,
      url('/img/home-section5-bg-sm@3x.webp') 3x
    );
  }
}

.float-ease-in-out {
  animation: float 2s infinite ease-in-out;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 1rem);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>

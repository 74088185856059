<template>
  <div
    id="app-reivews"
    class="u-w-full u-py-10 sm:u-py-[76px] u-overflow-hidden [&_.swiper-pagination-bullet]:u-bg-[#C5C5C5] [&_.swiper-pagination-bullet.swiper-pagination-bullet-active]:u-bg-[#525252]">
    <div class="swiper-wrapper u-bg-center u-bg-cover">
      <div
        v-for="(item, i) in props.source"
        :key="i"
        class="swiper-slide u-aspect-[420/450] u-max-w-[270px] sm:u-max-w-[376px] u-overflow-hidden u-bg-white u-shadow-[0_0_20px_#00000012] u-rounded-xl u-px-[16px] sm:u-px-[22px] u-pt-5 sm:u-pt-10 u-pb-5 sm:u-pb-[30px]">
        <div class="u-flex u-items-center">
          <img
            class="u-w-10 sm:u-w-[76px] u-h-10 sm:u-h-[76px] u-rounded-full u-object-cover"
            :alt="item.author?.name"
            :src="item.author?.avatarImage" />
          <div class="u-flex-1 u-px-2.5">
            <p
              class="u-m-0 u-text-[#1c1c1c] u-text-[18px] sm:u-text-[24px] u-leading-[22px] sm:u-leading-[29px]">
              {{ item.author?.name }}
            </p>
            <p
              class="u-m-0 u-text-[#1c1c1c] u-text-[14px] sm:u-text-[18px] u-leading-[18px] sm:u-leading-[21px]">
              {{ item.author?.position }}
            </p>
          </div>
          <div>
            <p
              class="u-m-0 u-text-[#1c1c1c] u-text-[18px] sm:u-text-[24px] u-leading-[22px] sm:u-leading-[29px]">
              &nbsp;
            </p>
            <p
              class="u-m-0 u-text-[#1c1c1c] u-text-[18px] sm:u-text-[18px] u-leading-[21px] sm:u-leading-[21px]">
              <q-icon
                v-for="i in 5"
                :key="i"
                :class="
                  i <= item.rating ? 'u-text-[#FFA033]' : 'u-text-[#ffa03333]'
                "
                :name="ionStar" />
            </p>
          </div>
        </div>
        <p
          class="u-m-0 u-mt-[12px] sm:u-mt-[29px] u-mb-[12px] sm:u-mb-[27px] u-text-[#1c1c1c] u-text-[18px] sm:u-text-[24px] u-leading-[22px] sm:u-leading-[29px] u-line-clamp-1">
          {{ item.title }}
        </p>
        <p
          class="u-m-0 u-text-[#1c1c1c] u-text-[12px] sm:u-text-[16px] u-leading-[20px] sm:u-leading-[24px] u-line-clamp-[9]">
          {{ item.content }}
        </p>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { ionStar } from '@quasar/extras/ionicons-v7'

/** @type {{ source: readonly import('src/api/cms').Review[] }} */
const props = defineProps({
  source: {
    type: Array,
    required: true
  }
})

const swiper = ref()

onMounted(async () => {
  /**
   * Waiting for Quasar to release ESM(mjs),
   * [see issue](https://github.com/quasarframework/quasar/issues/9455),
   * so using dymaic import in cjs.
   */
  const { Swiper } = await import('swiper')
  const { Pagination, EffectCoverflow, Autoplay } = await import(
    'swiper/modules'
  )
  const d = new Swiper('#app-reivews', {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000
    },
    effect: 'coverflow',
    slidesPerView: 'auto',
    grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 10,
      depth: 0,
      modifier: 1,
      slideShadows: false,
      scale: 0.7
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    modules: [Pagination, EffectCoverflow, Autoplay]
  })
  swiper.value = d
})

onUnmounted(() => {
  swiper.value?.destroy()
})
</script>

<style>
@import '/node_modules/swiper/swiper-bundle.min.css';
</style>

import script from "./app-reviews.vue?vue&type=script&setup=true&lang=js"
export * from "./app-reviews.vue?vue&type=script&setup=true&lang=js"

import "./app-reviews.vue?vue&type=style&index=0&id=b5f12c0c&lang=css"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
